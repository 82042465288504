import React from "react"
import Wrapper from "../components/wrapper";
import SectionTitle from "../components/sectionTitle";

const ErrorPage = () => {
    return (
        <Wrapper>
            <section className="bg-brand-dark-blue min-h-screen md:min-h-0 py-64 -mb-12">
                <div className="container mx-auto px-4 flex w-full h-full items-center">
                    <SectionTitle
                        wrapperClassName=""
                        alignCenter={true}
                        title="Page Not Found"
                        minititle="Error 404"
                        type="h1"
                        invert={true}
                        childrenClassName="w-full md:3/4 lg:w-4/6 xl:w-2/4 text-shadow-base"
                        buttonText="Back to Homepage"
                        buttonType="btn-light"
                        buttonLink="/"
                    >
                    </SectionTitle>
                </div>
            </section>
        </Wrapper>
    )
}

export default ErrorPage;